import type { IUserProfileCover } from 'src/types/user';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';

import { varAlpha, bgGradient } from 'src/theme/styles';
import Skeleton from '@mui/material/Skeleton';
import CopyBtn from 'src/components/common/CopyBtn';
import { IconButton, Typography } from '@mui/material';
import { RefreshOutlined, ArrowDropDown } from '@mui/icons-material';
import { FaSpinner } from 'react-icons/fa';
import WalletMenu from './wallet-menu';

// ----------------------------------------------------------------------

export function ProfileCover({
  id,
  name,
  avatarUrl,
  role,
  coverUrl,
  loading,
  loadingBalance,
  wallets,
  refreshBalance,
  onSelect,
}: IUserProfileCover) {
  const theme = useTheme();

  const idDisplay = `${id}`.replace(/.*_/, '***_');

  return (
    <Box
      sx={{
        ...bgGradient({
          color: `0deg, ${varAlpha(theme.vars.palette.primary.darkerChannel, 0.8)}, ${varAlpha(theme.vars.palette.primary.darkerChannel, 0.8)}`,
          imgUrl: coverUrl,
        }),
        height: 1,
        color: 'common.white',
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          left: { md: 24 },
          bottom: { md: 24 },
          zIndex: { md: 10 },
          pt: { xs: 6, md: 0 },
          position: { md: 'absolute' },
        }}
      >
        {loading ? (
          <Skeleton
            variant="circular"
            sx={{
              mx: 'auto',
              width: { xs: 64, md: 128 },
              height: { xs: 64, md: 128 },
              border: `solid 2px ${theme.vars.palette.common.white}`,
            }}
          />
        ) : (
          <Avatar
            alt={name}
            src={avatarUrl}
            sx={{
              mx: 'auto',
              width: { xs: 64, md: 128 },
              height: { xs: 64, md: 128 },
              border: `solid 2px ${theme.vars.palette.common.white}`,
            }}
          >
            {name?.charAt(0).toUpperCase()}
          </Avatar>
        )}

        <Box>
          <ListItemText
            sx={{ mt: 3, ml: { md: 3 }, textAlign: { xs: 'center', md: 'unset' } }}
            primary={!loading ? name : <Skeleton variant="text" sx={{ fontSize: '1em' }} />}
            secondary={
              !loading ? (
                refreshBalance ? (
                  <Stack flexDirection="row" alignItems="center" justifyContent="center">
                    {'  '}
                    <WalletMenu title={role} wallets={wallets ?? []} onSelect={onSelect} />

                    {loadingBalance ? (
                      <FaSpinner />
                    ) : (
                      <>
                        <IconButton
                          sx={{ ml: '0px', padding: '0px', color: 'inherit' }}
                          onClick={refreshBalance}
                        >
                          <RefreshOutlined sx={{ fontSize: 18 }} />
                        </IconButton>
                      </>
                    )}
                  </Stack>
                ) : (
                  role
                )
              ) : (
                <Skeleton variant="text" sx={{ fontSize: '1em' }} />
              )
            }
            primaryTypographyProps={{ typography: 'h4' }}
            secondaryTypographyProps={{
              mt: 0.5,
              color: 'inherit',
              component: 'span',
              typography: 'body2',
              sx: { opacity: 0.48 },
            }}
          />
          {loading ? (
            <Skeleton variant="text" sx={{ fontSize: '1em' }} />
          ) : (
            <Stack
              fontSize={12}
              fontWeight={500}
              color="#bfc8cf"
              gap={1}
              mt={1}
              ml={{ xs: 0, md: 3 }}
              flexDirection="row"
              alignItems="center"
              justifyContent={{ xs: 'center', md: 'unset' }}
            >
              <Typography variant="body2" color="text.secondary" data-id={"lobby__user_id_c"}>
                ID: <span>{idDisplay}</span>
              </Typography>
              <CopyBtn text={idDisplay ?? ''} colorClassName="text-[#bfc8cf]" />
            </Stack>
          )}
        </Box>
      </Stack>
    </Box>
  );
}
