import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, ListItemText, Typography } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers/icons';
import { UserWallet } from 'src/types/user';
import { FaWallet } from 'react-icons/fa';
import { fNumber } from 'src/utils/format-number';

interface IProps {
  title: string;
  wallets: UserWallet[];
  onSelect?: (wallet: UserWallet) => void;
}

export default function WalletMenu({ title, wallets, onSelect }: IProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (wallet?: UserWallet) => {
    return () => {
      setAnchorEl(null);
      if (!wallet) {
        return;
      }
      onSelect && onSelect(wallet);
    };
  };

  return (
    <div>
      <IconButton sx={{ ml: '0px', padding: '0px', color: 'inherit' }} onClick={handleClick}>
        <Typography variant="body2">{title}</Typography>
        <ArrowDropDownIcon sx={{ fontSize: 18 }} />
      </IconButton>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {wallets.map((wallet) => (
          <MenuItem key={wallet.id} onClick={handleClose(wallet)} sx={{ minWidth: 100 }}>
            <FaWallet />
            <ListItemText
              primary={wallet.currency}
              secondary={`${fNumber(wallet.amount)}`}
              sx={{ ml: 1 }}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
