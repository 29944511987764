import type { IconButtonProps } from '@mui/material/IconButton';

import { useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { usePathname, useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

import { varAlpha } from 'src/theme/styles';

import { AnimateAvatar } from 'src/components/animate';
import { Iconify } from 'src/components/iconify';
import { Scrollbar } from 'src/components/scrollbar';

import { useGetIdentity, useLogout, useTranslate } from 'react-admin';
import { AccountButton } from './account-button';
import { SignOutButton } from './sign-out-button';

// ----------------------------------------------------------------------

export type AccountDrawerProps = IconButtonProps & {
  data?: {
    label: string;
    href: string;
    icon?: React.ReactNode;
    info?: React.ReactNode;
  }[];
};

export function AccountDrawer({ data = [], sx, ...other }: AccountDrawerProps) {
  const theme = useTheme();

  const router = useRouter();

  const pathname = usePathname();

  const { data: userData } = useGetIdentity();
  const user = userData;

  const logout = useLogout();

  const [open, setOpen] = useState(false);

  const handleOpenDrawer = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClickItem = useCallback(
    (path: string) => {
      handleCloseDrawer();
      router.push(path);
    },
    [handleCloseDrawer, router]
  );
  const t = useTranslate();

  const name = user?.name?.trim() || user?.username || user?.email || '';

  const renderAvatar = (
    <AnimateAvatar
      width={96}
      slotProps={{
        avatar: { src: user?.avatar || user?.image, alt: name },
        overlay: {
          border: 2,
          spacing: 3,
          color: `linear-gradient(135deg, ${varAlpha(theme.vars.palette.primary.mainChannel, 0)} 25%, ${theme.vars.palette.primary.main} 100%)`,
        },
      }}
    >
      {name.charAt(0).toUpperCase()}
    </AnimateAvatar>
  );

  return (
    <>
      <AccountButton
        open={open}
        onClick={handleOpenDrawer}
        photoURL={user?.avatar || user?.image || ''}
        displayName={name}
        sx={sx}
        {...other}
      />

      <Drawer
        open={open}
        onClose={handleCloseDrawer}
        anchor="right"
        slotProps={{ backdrop: { invisible: true } }}
        PaperProps={{ sx: { width: 320 } }}
      >
        <IconButton
          onClick={handleCloseDrawer}
          sx={{ top: 12, left: 12, zIndex: 9, position: 'absolute' }}
        >
          <Iconify icon="mingcute:close-line" />
        </IconButton>

        <Scrollbar>
          <Stack alignItems="center" sx={{ pt: 8 }}>
            {renderAvatar}

            <Typography variant="subtitle1" noWrap sx={{ mt: 2 }}>
              {name}
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary', mt: 0.5 }} noWrap>
              {user?.email}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1} flexWrap="wrap" justifyContent="center" sx={{ p: 3 }}>
            {/* {[...Array(3)].map((_, index) => (
              <Tooltip
                key={_mock.fullName(index + 1)}
                title={`Switch to: ${_mock.fullName(index + 1)}`}
              >
                <Avatar
                  alt={_mock.fullName(index + 1)}
                  src={_mock.image.avatar(index + 1)}
                  onClick={() => {}}
                />
              </Tooltip>
            ))} */}

            {/* <Tooltip title="Add account">
              <IconButton
                sx={{
                  bgcolor: varAlpha(theme.vars.palette.grey['500Channel'], 0.08),
                  border: `dashed 1px ${varAlpha(theme.vars.palette.grey['500Channel'], 0.32)}`,
                }}
              >
                <Iconify icon="mingcute:add-line" />
              </IconButton>
            </Tooltip> */}
          </Stack>

          {/*<Stack*/}
          {/*  sx={{*/}
          {/*    py: 3,*/}
          {/*    px: 2.5,*/}
          {/*    borderTop: `dashed 1px ${theme.vars.palette.divider}`,*/}
          {/*    borderBottom: `dashed 1px ${theme.vars.palette.divider}`,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {data.map((option) => {*/}
          {/*    const rootLabel = pathname.includes('/dashboard') ? 'Home' : 'Dashboard';*/}

          {/*    const rootHref = pathname.includes('/dashboard') ? '/' : paths.dashboard.root;*/}

          {/*    return (*/}
          {/*      <MenuItem*/}
          {/*        key={option.label}*/}
          {/*        onClick={() => handleClickItem(option.label === 'Home' ? rootHref : option.href)}*/}
          {/*        sx={{*/}
          {/*          py: 1,*/}
          {/*          color: 'text.secondary',*/}
          {/*          '& svg': { width: 24, height: 24 },*/}
          {/*          '&:hover': { color: 'text.primary' },*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        {option.icon}*/}

          {/*        <Box component="span" sx={{ ml: 2 }}>*/}
          {/*          {option.label === 'Home' ? t(rootLabel) : t(option.label)}*/}
          {/*        </Box>*/}

          {/*        {option.info && (*/}
          {/*          <Label color="error" sx={{ ml: 1 }}>*/}
          {/*            {option.info}*/}
          {/*          </Label>*/}
          {/*        )}*/}
          {/*      </MenuItem>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</Stack>*/}
        </Scrollbar>

        <Box sx={{ p: 2.5 }}>
          <SignOutButton
            onClose={handleCloseDrawer}
            onClick={() => {
              const keys = Object.keys(localStorage);
              keys.map((key) => {
                if (key.includes('listParams')) {
                  localStorage.removeItem(key);
                }
                return;
              });
              logout();
            }}
          />
        </Box>
      </Drawer>
    </>
  );
}
