// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/',
  BASE_PATH: '',
};

// ----------------------------------------------------------------------

export const paths = {
  faqs: '/faqs',
  minimalStore: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    amplify: {
      signIn: `${ROOTS.AUTH}/amplify/sign-in`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      signUp: `${ROOTS.AUTH}/amplify/sign-up`,
      updatePassword: `${ROOTS.AUTH}/amplify/update-password`,
      resetPassword: `${ROOTS.AUTH}/amplify/reset-password`,
    },
    jwt: {
      signIn: `${ROOTS.AUTH}/jwt/sign-in`,
      signUp: `${ROOTS.AUTH}/jwt/sign-up`,
    },
    firebase: {
      signIn: `${ROOTS.AUTH}/firebase/sign-in`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      signUp: `${ROOTS.AUTH}/firebase/sign-up`,
      resetPassword: `${ROOTS.AUTH}/firebase/reset-password`,
    },
    auth0: {
      signIn: `${ROOTS.AUTH}/auth0/sign-in`,
    },
    supabase: {
      signIn: `${ROOTS.AUTH}/supabase/sign-in`,
      verify: `${ROOTS.AUTH}/supabase/verify`,
      signUp: `${ROOTS.AUTH}/supabase/sign-up`,
      updatePassword: `${ROOTS.AUTH}/supabase/update-password`,
      resetPassword: `${ROOTS.AUTH}/supabase/reset-password`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    two: `${ROOTS.BASE_PATH}/two`,
    three: `${ROOTS.BASE_PATH}/three`,
    group: {
      root: `${ROOTS.BASE_PATH}/group`,
      five: `${ROOTS.BASE_PATH}/group/five`,
      six: `${ROOTS.BASE_PATH}/group/six`,
    },

    // cg admin
    currency: `${ROOTS.BASE_PATH}/currency`,
    hashMonitor: `${ROOTS.BASE_PATH}/hash-monitor`,
    account: `${ROOTS.BASE_PATH}/player`,
    game: {
      root: `${ROOTS.BASE_PATH}/game`,
      gameDetail: `${ROOTS.BASE_PATH}/game-detail`,
      gameRule: `${ROOTS.BASE_PATH}/game-rule`,
      gameDocument: `${ROOTS.BASE_PATH}/game-document`,
    },
    transaction: `${ROOTS.BASE_PATH}/transaction`,
    agency: {
      root: `${ROOTS.BASE_PATH}/agency`,
      create: `${ROOTS.BASE_PATH}/agency/create`,
      new: `${ROOTS.BASE_PATH}/agency/new`,
      edit: (id: string) => `${ROOTS.BASE_PATH}/agency/${id}/edit`,
    },
  },
};
