import { m } from 'framer-motion';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { RouterLink } from 'src/routes/components';

import { SimpleLayout } from 'src/layouts/simple';
import { PageNotFoundIllustration } from 'src/assets/illustrations';

import { varBounce, MotionContainer } from 'src/components/animate';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export function NotFoundView({
  title,
  content,
  backCallback,
}: {
  title?: string;
  content?: string;
  backCallback?: any;
}) {
  const { t } = useTranslation();
  return (
    <SimpleLayout content={{ compact: true }}>
      <Container component={MotionContainer}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            {title ?? t('portal.lobby.error.pagenotfound')}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            {content ?? t('portal.lobby.error.invalid')}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <PageNotFoundIllustration sx={{ my: { xs: 5, sm: 10 } }} />
        </m.div>

        <Button
          component={RouterLink}
          href={'/'}
          size="large"
          variant="contained"
          onClick={
            !backCallback
              ? undefined
              : (e) => {
                  e.preventDefault();
                  backCallback();
                }
          }
        >
          {!backCallback ? t('portal.lobby.button.back-home') : t('portal.lobby.button.back')}
        </Button>
      </Container>
    </SimpleLayout>
  );
}
