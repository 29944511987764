const prefix = 'webhub';

export const lsKeys = {
  TOKEN: 'token',
  USER: 'user',
  NOTIFICATION: 'notification',
  GAME_ID: 'game_id',
  AGENCY_ID: 'agency_id',
  SESSION_TOKEN: 'session_token',
  BET_AMOUNT: 'bet_amount',
};

const _safeParse = (value: any) => {
  try {
    return JSON.parse(value || null);
  } catch (e) {
    return value;
  }
};

export const get = (key: string) => {
  const fullKey = `${prefix}:${key}`;

  const value = localStorage.getItem(fullKey);

  return _safeParse(value);
};

export const set = (key: string, value: any) => {
  const fullKey = `${prefix}:${key}`;
  const vValue = JSON.stringify(value);

  return localStorage.setItem(fullKey, vValue);
};

export const remove = (key: string) => {
  return localStorage.removeItem(`${prefix}.${key}`);
};

const ls = { get, set, remove };

export default ls;
