import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import i18n from 'i18next';
import { NotFoundView, View403 } from 'src/sections/error';
import { LobbyView } from './LobbyView';
import { _mock } from 'src/_mock';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { mapLangCodeToCountryCode } from 'src/utils/param';
import { endCode } from 'src/utils/crypto';
import { lsKeys, set } from 'src/utils/local-storage';
import { UserWallet } from 'src/types/user';

interface QueryParams {
  agency_id: string;
  session_token: string;
  lang?: 'en' | 'zh';
  color_scheme?: 'dark' | 'light'; // default dark
}
function getQueryParams(params: URLSearchParams): QueryParams {
  const result: any = {};
  params.forEach((value, key) => {
    result[key] = value;
  });
  return result as QueryParams;
}

// https://cgame.notion.site/Appendix-99f3247d558c482a8bea444dec948f33#d235cbb98b5246dcb6a6bfcc91be9144
const Endpoints = {
  PORTAL_ENDPOINT: process.env.REACT_APP_API_URL as string,
  USER_ENDPOINT: process.env.REACT_APP_USER_ENDPOINT as string,
  WALLET_ENDPOINT: process.env.REACT_APP_WALLET_API_URL as string,
};

function View() {
  const { t } = useTranslation();
  const [query] = useSearchParams();
  const { lang, session_token, agency_id, color_scheme, ...restQuery } = useMemo(
    () => getQueryParams(query),
    [query]
  );

  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [loadingBalance, setLoadingBalance] = useState(false);

  const [user, setUser] = useState<any>(undefined);
  const [wallets, setWallets] = useState<UserWallet[]>([]);
  const [games, setGames] = useState<any[]>([]);

  const selectedWallet = useRef<UserWallet | null>(null);

  const accessToken = useRef<string | null>(null);
  const [accessTokenEncoded, setAccessTokenEncoded] = useState<string | undefined>(undefined);

  const colorScheme = color_scheme ?? 'dark';

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const saveAccessToken = async (token: string) => {
    set(lsKeys.TOKEN, token);
    accessToken.current = token;

    const accessTokenEndcrypted = await endCode(token);
    setAccessTokenEncoded(accessTokenEndcrypted);
  };

  const loadWalets = useCallback(
    async (token?: string) => {
      if (!token) {
        return;
      }

      try {
        const response = await fetch(`${Endpoints.WALLET_ENDPOINT}/wallet/v1/lobby/wallets`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        });

        const data = await response.json();
        if (data.code !== 'Success') {
          return;
        }

        setWallets(data.data);

        selectedWallet.current =
          data.data.find((w: UserWallet) => w.currency === user?.currency) || null;

        if (selectedWallet.current) {
          setUser({
            ...user,
            balance: selectedWallet.current?.amount,
          });
        }

        return data;
      } catch (error) {
        console.error('loadWalet Error:', error);
      }
    },
    [user, setUser, setWallets]
  );

  const authenticate = useCallback(async () => {
    if (!agency_id || !session_token) {
      setError(new Error(t('portal.lobby.error.missingLogin'))); // Set error message if parameters are missing
      setLoading(false);
      return;
    }

    try {
      const onLoginSuccess = (user: any) => {
        setUser(user);
        set(lsKeys.USER, user);
        setError(undefined); // Clear error if login is successful
      };

      setLoading(true);
      fetch(`${Endpoints.USER_ENDPOINT}/api/wallet/v1/lobby/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          session_token,
          agency_id,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (!res.code || res.code !== 'Success') {
            throw new Error(t('portal.lobby.error.invalidSession'));
          }

          const player = res.data?.player;
          onLoginSuccess({
            id: player?.id,
            agencyPlayerId: player?.agency_player_id,
            displayName: player?.name,
            balance: player?.default_wallet?.amount,
            currency: player?.default_wallet?.currency,
            photoURL: _mock.image.avatar(22),
            coverUrl: _mock.image.cover(3),
            country: _mock.countryNames(1),
          });
          saveAccessToken(res.data?.access_token);
          loadWalets(res.data?.access_token);
        })
        .catch((error) => {
          console.error('login Error:', error);
          setError(error);
        })
        .finally(() => {
          setTimeout(() => setLoading(false), 500);
        });
    } catch (error: any) {
      console.error('Authentication error:', error);
      setError(new Error(t('portal.lobby.error.invalidSession'))); // Set error message
    }
  }, [agency_id, session_token, t, loadWalets]);

  const loadGames = useCallback(async () => {
    try {
      const response = await fetch(`${Endpoints.PORTAL_ENDPOINT}/portal/games`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      const data = await response.json();
      setGames(data);

      return data;
    } catch (error) {
      console.error('[loadGames] Error:', error);
    }
  }, [setGames]);

  useEffect(() => {
    loadGames();
  }, [loadGames]);

  useEffect(() => {
    authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lang) {
      changeLanguage(mapLangCodeToCountryCode(lang));
    }
  }, [lang]);

  if (!loading && error) {
    return <ErrorPage error={error} />;
  }

  return (
    <LobbyView
      loading={loading}
      loadingBalance={loading || loadingBalance}
      colorScheme={colorScheme}
      components={{
        user: true,
      }}
      user={user}
      wallets={wallets}
      games={games}
      authQueryString={{
        agency_id,
        session_token,
        lang,
        ...restQuery,
      }}
      refreshBalance={() => {
        if (accessToken.current) {
          setLoadingBalance(true);
          loadWalets(accessToken.current).finally(() => setLoadingBalance(false));
        }
      }}
      onSelect={(wallet) => {
        selectedWallet.current = wallet;
        setUser({
          ...user,
          currency: wallet.currency,
          balance: wallet.amount,
        });
      }}
    />
  );
}

export enum IframeCommunicationEvent {
  GO_BACK = 'go_back',
  STATUS_BAR_COLOR = 'status_bar_color',
}

function ErrorPage({ error }: { error: Error }) {
  const { t, i18n } = useTranslation();
  const code = error instanceof AxiosError ? error.response?.status || 401 : 401;
  if ([401, 403].includes(code)) return <View403 />;
  return (
    <NotFoundView
      title={t('portal.lobby.error.title')}
      content={error.message}
      backCallback={() => {
        const isInsideIframe = window.self !== window.top;
        if (isInsideIframe) {
          window.parent.postMessage(IframeCommunicationEvent.GO_BACK, "*")
          return
        }

        window.history.back();
      }}
    />
  );
}

export default View;
