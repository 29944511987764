import * as qs from 'qs';
import _invert from "lodash/invert";

export function mergeQueryParams<T extends object>(url: string, parameters: T) {
  const filteredParameters = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(parameters).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ''
    )
  );
  const keys = Object.keys(filteredParameters);
  let queryUrl: string = url;
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(filteredParameters);
  }
  return queryUrl;
}

const LangCountry: Record<string, string> = {
  zh: 'cn',
  vi: 'vn',
  ar: 'sa',
  en: 'us',
  hi: 'in',
  ko: 'kr',
}
const CountryLang = _invert(LangCountry)

export function mapLangCodeToCountryCode(lang: string) {
  const l = LangCountry[lang.toLowerCase()];
  return l ?? lang;
}

export function mapCountryCodeToLang(lang: string) {
  const l = CountryLang[lang.toLowerCase()];
  return l ?? lang;
}
