import React, { useCallback, useState } from 'react';
import { LuCopy, LuCheckCheck } from 'react-icons/lu';
import { FaCheck } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

export default function CopyBtn(props: {
  text: string;
  colorClassName?: string;
  className?: string;
}) {
  const { t } = useTranslation();
  const { text, colorClassName, className } = props;
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const copy = useCallback(() => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setChecked(true);
        setError(null);
        setTimeout(() => {
          setChecked(false);
        }, 1000);
      })
      .catch((err) => {
        console.warn(err);
        setError('Failed to copy text');
      });
  }, [text]);

  return (
    <Box
      component="span"
      onClick={copy}
      color={checked ? '#84cc16' : '#bfc8cf'}
      mt="2px"
      sx={{
        cursor: 'pointer',
      }}
      className={className}
    >
      {checked ? <LuCheckCheck /> : <LuCopy />}
      {error && (
        <Box component="span" ml={2} color="#ef4444">
          {error}
        </Box>
      )}
      {checked && (
        <Box
          component={'div'}
          display="flex"
          position="fixed"
          fontSize="12px"
          color="white"
          top="50%"
          left="50%"
          bgcolor="#333"
          p={1}
          alignItems="center"
          gap={1}
          borderRadius="md"
          zIndex="10"
          sx={{
            transform: 'translate(-50%, -50%)',
          }}
        >
          <FaCheck />
          <Typography variant="body2">{t('portal.lobby.notification.copySuccessful')}</Typography>
        </Box>
      )}
    </Box>
  );
}
